<template>
  <div class="page-container">
    <page-header v-if="!setId"></page-header>
    <div class="dashboard-container">
      <div class="page-card">
        <div class="page-card-body" >
          <div class="descriptions" style="padding: 20px;">
            <div class="descriptions-header">
              <div class="descriptions-title">基础信息</div>
            </div>
            <div class="descriptions-view" v-if="userInfo">
              <table>
                <tbody>
                <tr class="descriptions-row">
                  <td class="descriptions-item" colspan="1">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">姓名</span>
                      <span class="descriptions-item-content">{{ userInfo.farmer && userInfo.farmer.name }}</span>
                    </div>
                  </td>
                  <td class="descriptions-item" colspan="1">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">电话号码</span>
                      <span class="descriptions-item-content">{{ userInfo.farmer && userInfo.farmer.phone }}</span>
                    </div>
                  </td>
                  <td class="descriptions-item" colspan="1"></td>
                </tr>
                <tr class="descriptions-row">
                  <td class="descriptions-item" colspan="1">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">所在地区</span>
                      <span class="descriptions-item-content" v-if="userInfo.farmer">
                        {{ [userInfo.farmer.province, userInfo.farmer.city, userInfo.farmer.area].join('') }}
                      </span>
                    </div>
                  </td>
                  <td class="descriptions-item" colspan="1">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">详细地址</span>
                      <span class="descriptions-item-content">
                        {{ userInfo.farmer && userInfo.farmer.address }}
                      </span>
                    </div>
                  </td>
                  <td class="descriptions-item" colspan="1"></td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="descriptions" style="padding: 20px;" >
            <div class="descriptions-header">
              <div class="descriptions-title">踏勘信息</div>
            </div>
            <div class="descriptions-view" v-if="userInfo">
              <table>
                <tbody>
                <tr class="descriptions-row">
                  <td class="descriptions-item" colspan="1">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">踏勘状态</span>
                      <span class="descriptions-item-content">
                        {{ TASK_STATE_NAME[userInfo.state] }}
                      </span>
                    </div>
                  </td>
                  <td class="descriptions-item" colspan="1">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">打卡记录</span>
                      <span class="descriptions-item-content">
                        <el-button type="primary" size="mini" @click="dialogVisible=true">查看</el-button>
                      </span>
                    </div>
                  </td>
                  <td class="descriptions-item" colspan="1"></td>
                </tr>
                <tr class="descriptions-row" v-if="userInfo.state === 'FAIL'">
                  <td class="descriptions-item" colspan="1">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">不通过原因</span>
                      <span class="descriptions-item-content">
                        {{ userInfo.failMsg }}
                      </span>
                    </div>
                  </td>
                  <td class="descriptions-item" colspan="1">
                  </td>
                  <td class="descriptions-item" colspan="1"></td>
                </tr>
                <tr class="descriptions-row">
                  <td class="descriptions-item" colspan="1">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">所属公司</span>
                      <span class="descriptions-item-content">
                        {{ userInfo | distributor }}
                      </span>
                    </div>
                  </td>
                  <td class="descriptions-item" colspan="1">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">踏勘人员</span>
                      <span class="descriptions-item-content">
                        {{ userInfo.user && userInfo.user.name }}
                      </span>
                    </div>
                  </td>
                  <td class="descriptions-item" colspan="1"></td>
                </tr>
                <tr class="descriptions-row">
                  <td class="descriptions-item" colspan="1">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">房屋类型</span>
                      <span class="descriptions-item-content">
                        {{ userInfo.roofTypeModel==null?"":userInfo.roofTypeModel.name }}
                      </span>
                    </div>
                  </td>
                  <td class="descriptions-item" colspan="1">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">朝向角度</span>
                      <span class="descriptions-item-content">
                        {{ userInfo.angle }}
                      </span>
                    </div>
                  </td>
                  <td class="descriptions-item" colspan="1"></td>
                </tr>
                <tr class="descriptions-row">
                  <td class="descriptions-item" colspan="1">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">并网点距离</span>
                      <span class="descriptions-item-content">
                        {{ userInfo.distance==null?"":userInfo.distance+"m" }}
                      </span>
                    </div>
                  </td>
                  <td class="descriptions-item" colspan="1"></td>
                  <td class="descriptions-item" colspan="1"></td>
                </tr>
                <tr class="descriptions-row">
                  <td class="descriptions-item" colspan="3">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">房屋照片</span>
                      <span class="descriptions-item-content" style="display: flex;flex-wrap: wrap;">
                        <el-image style="width: 160px;height: 90px;margin-right: 10px;margin-bottom: 10px;"
                                  fit="cover"
                                  :preview-src-list="houseImgShow(userInfo)"
                                  v-for="iter in houseImgShow(userInfo)"
                                  :key="iter"
                                  :src="iter"></el-image>
                      </span>
                    </div>
                  </td>
                </tr>
                <tr class="descriptions-row">
                  <td class="descriptions-item" colspan="1">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">俯视房屋照片</span>
                      <span class="descriptions-item-content" style="display: flex;flex-wrap: wrap;">
                        <el-image v-if="userInfo.houseDownImg"
                                  fit="cover"
                                  style="width: 160px;height: 90px;margin-right: 10px;margin-bottom: 10px;"
                                  :preview-src-list="[getSourceUrl(userInfo.houseDownImg)]"
                                  :src="getSourceUrl(userInfo.houseDownImg)"></el-image>
                      </span>
                    </div>
                  </td>
                </tr>
                <tr class="descriptions-row">
                  <td class="descriptions-item" colspan="1">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">备注</span>
                      <span class="descriptions-item-content">
                        <!--這裡的備註 是踏勘錄入時的備註-->
                        {{ userInfo.remarks }}
                      </span>
                    </div>
                  </td>
                  <td class="descriptions-item" colspan="1"></td>
                  <td class="descriptions-item" colspan="1"></td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>

          <!-- 审核信息 -->
          <div class="descriptions" style="padding: 20px;"
               v-if="userInfo && userInfo.state === 'EXAMINE' && checkPermission('tk:examine')">
            <div class="descriptions-header">
              <div class="descriptions-title">审核</div>
            </div>
            <div class="descriptions-view">
              <el-button type="primary" size="mini" @click="examineInfoShow=true">审核</el-button>
            </div>
          </div>

        </div>
      </div>
    </div>

    <div class="clock-in">
      <el-dialog title="打卡记录"
                 :visible.sync="dialogVisible"
                 width="45%">
        <div>
          <div v-if="userInfo">
            <el-row>
              <el-col :span="4">序号</el-col>
              <el-col :span="6">签到人员</el-col>
              <el-col :span="8">签到地点</el-col>
              <el-col :span="6">签到时间</el-col>
            </el-row>
            <div style="max-height: 450px;overflow: auto;">
              <el-row v-for="(iter, index) in userInfo.locations"
                      :key="iter.id"
                      style="padding: 10px 0;">
                <el-col :span="4">{{ index+1 }}</el-col>
                <el-col :span="6">{{ (iter.user && iter.user.name) || '-' }}</el-col>
                <el-col :span="8">{{ iter.name }}</el-col>
                <el-col :span="6">{{ iter.time }}</el-col>
              </el-row>
            </div>
          </div>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button type="info" size="mini" @click="dialogVisible = false">关闭</el-button>
        </div>
      </el-dialog>
    </div>

    <div class="to-examine-container">
      <el-dialog title="审核信息"
                 :visible.sync="examineInfoShow"
                 width="30%">
        <div>
          <el-form :model="examineInfo"
                   :rules="examineInfoRules"
                   ref="examineInfoRef">
            <el-form-item label="是否通过" prop="passed">
              <el-radio v-model="examineInfo.passed" :label="true">通过</el-radio>
              <el-radio v-model="examineInfo.passed" :label="false">拒绝</el-radio>
            </el-form-item>
            <el-form-item label="备注" prop="msg">
              <el-input v-model="examineInfo.msg" placeholder="备注"></el-input>
            </el-form-item>
          </el-form>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" size="mini" @click="submitExamine">提交</el-button>
        </div>
      </el-dialog>
    </div>

  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader";
import { wordOrderById} from "@/api/common";
import {TASK_STATE_NAME} from "@/utils/config";
import helper from "@/utils/helper";
import {wordOrderExamine} from "@/api/apis";
import checkPermission from "@/utils/checkPermission";

export default {
  name: 'SurveyInfo',
  components: {PageHeader},
  data(){
    return {
      userInfo: null,
      TASK_STATE_NAME,

      dialogVisible: false,
      //
      examineInfoShow: false,
      examineInfo: {
        passed: false,
        msg: '',
      },
      examineInfoRules: {

      }
    }
  },
  filters: {
    distributor(item){
      return item.createUser?.distributor?.name || '-'
    },
  },
  props: {
    setId: {
      type: String,
      default(){
        return ''
      }
    }
  },
  computed: {
    thisId(){
      return this.setId || this.$route.params.id
    }
  },
  mounted() {
    this.getInfo()
  },
  methods: {
    checkPermission,
    getSourceUrl: helper.getSourceUri,
    houseImgShow(item){
      const res = []
      const { houseImg } = item
      if (houseImg) {
        const arr = houseImg.split(',')
        arr.forEach(iter => {
          res.push(this.getSourceUrl(iter))
        })
      }
      return res
    },
    /**
     * 获取用户信息
     */
    getInfo(){
      wordOrderById(this.thisId).then(res => {
        this.userInfo = res
      })
    },
    /**
     * 提交审核
     */
    submitExamine(){
      const status = this.examineInfo.passed ? 'ADOPT' : 'REFUSE'
      this.examineInfoShow = false
      this.toExamine(status)
    },
    /**
     * 审核
     * 通过 ADOPT
     * 拒绝 REFUSE
     * 取消 CANCEL
     */
    toExamine(status){
      // 通过
      const adoptHandler = () => {
        wordOrderExamine(this.thisId, {
          msg: this.examineInfo.msg,
          state: 'SUCCESS',
          workOrderId: this.thisId
        }).then(() => {
          this.$message.success('提交成功')
          setTimeout(() => {
            this.$router.go(-1)
          }, 500)
        })
      }
      // 不通过
      const refuseHandler = () => {
        wordOrderExamine(this.thisId, {
          msg: this.examineInfo.msg,
          state: 'FAIL',
          workOrderId: this.thisId
        }).then(() => {
          this.$message.success('提交成功')
          setTimeout(() => {
            this.$router.go(-1)
          }, 500)
        })
      }
      // 取消
      const cancelHandler = () => {

      }

      switch (status) {
        case 'ADOPT':
          adoptHandler()
          break
        case 'REFUSE':
          refuseHandler()
          break
        case 'CANCEL':
          cancelHandler()
          break
      }
    },
  }
}
</script>

<style lang="scss" scoped>
/deep/ .clock-in .el-dialog__body{
  padding: 5px 20px;
}
// 审核
/deep/ .to-examine-container .el-form-item{
  margin-bottom: 5px;
}
/deep/ .to-examine-container .el-dialog__body{
  padding: 10px 20px;
}
</style>